<template>
  <div id="agreement-clause">
    <div v-if="variant === 'signup'">
      <p>
        <small><small>By submitting this form, you confirm that you agree to the storing and processing of your personal data by Aira as described in our
          <a
            href="https://aira.io/terms-of-service"
            target="_blank"
          >
            Terms of Service
          </a> and
          <a
            href="https://aira.io/privacy-policy"
            target="_blank"
          >Privacy Policy
          </a>
        </small></small>
      </p>
    </div>
    <div v-if="variant === 'guestUpgrade'">
      <p><small><small>*Your new plan’s minutes will be added to your account upon today’s purchase. Subsequent monthly payments will occur at the beginning of each billing cycle. If you've ordered hardware, your Horizon Kit charge will take place after it arrives; the package will require a signature at the door. All transactions are processed as the nearest United States Dollar equivalent. Cancel anytime by calling Customer Care.</small></small></p>
      <p><small><small>*If you wish to change which currency is displayed, please contact Customer Care.</small></small></p>
    </div>
    <div v-if="variant === 'explorerUpgrade'">
      <p>
        <small><small>*Your new plan’s minutes will be added to your account upon today’s purchase. Subsequent monthly payments will occur at the beginning of each billing cycle. Cancel anytime by calling Customer Care.
        </small></small>
      </p>
      <p><small><small>*If you wish to change which currency is displayed, please contact Customer Care.</small></small></p>
    </div>
    <div v-if="variant === 'minutePurchase'">
      <p>
        <small>
          <small>
            *All Purchased Minute sales are final and not refundable.
          </small>
        </small>
      </p>
      <p>
        <small>
          <small>
            *Purchased minutes never expire and will be used after your Plan minutes are consumed.
          </small>
        </small>
      </p>
      <p>
        <small>
          <small>
            *If you wish to change which currency is displayed, please
            <a
              href="https://aira.io/contact-us/"
              target="_blank"
            >
              contact Customer Care
            </a>
          </small>
        </small>
      </p>
    </div>
    <b-container v-if="links">
      <b-row>
        <b-col>
          <small><small>
            <a
              href="https://aira.io/faq"
              target="_blank"
            >
              FAQs
            </a>
          </small></small>
        </b-col>
        <b-col>
          <small><small>
            <a
              href="https://aira.io/terms-of-service"
              target="_blank"
            >
              Terms of Service
            </a>
          </small></small>
        </b-col>
        <b-col>
          <small><small>
            <a
              href="https://aira.io/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </small></small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AgreementClause',
  props: {
    variant: {
      type: String,
      required: true,
    },
    links: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang='scss' scoped>
#agreement-clause {
  margin-top: 25px;
  line-height: 11px;
  font-weight: 500;
  text-align: center !important;
}
</style>
