<template>
  <div id="signup-step-1">
    <!-- YOUR PLAN SECTION -->
    <FormSection
      title="Your Plan"
    >
      <SignupPlan />
    </FormSection>
    <!-- HARDWARE KIT SECTION -->
    <FormSection
      v-if="hardwareOptions.length"
      title="Horizon Kit"
      description="Add the Horizon Kit to your plan! Choose between two payment options. Please note all payments made are non-refundable.<br>International shipping costs may apply."
    >
      <FormRadio
        :options="hardwareOptions"
        get="hardwareSelected"
        set="SET_HARDWARE_SELECTED"
      />
    </FormSection>
    <!-- HARDWARE KIT PREFERENCES SECTION -->
    <div>
      <FormSection
        v-if="hardwarePreferences.length"
        :title="hardwareSelectedInfo.name + ' Preferences'"
      >
        <div
          v-for="preference in hardwarePreferences"
          :key="preference.get"
        >
          <FormSection
            :description="preference.description"
          >
            <FormGroup
              :for="preference.get"
              :label="preference.label"
              class="w-100"
            >
              <FormSelect
                :options="preference.options"
                :bind-id="preference.get"
                :get="preference.get"
                :set="preference.set"
                required
              />
            </FormGroup>
          </FormSection>
        </div>
      </FormSection>
    </div>
    <!-- SHIPPING ADDRESS SECTION -->
    <FormSection
      v-if="showShipping"
      title="Shipping Address"
    >
      <!-- COUNTRY -->
      <FormGroup
        for="shippingCountry"
        label="Country"
        class="w-100"
      >
        <FormSelect
          :options="countryOptions"
          bind-id="shippingCountry"
          required
          get="shippingCountry"
          set="UPDATE_SHIPPING_COUNTRY"
        />
      </FormGroup>
      <!-- FIRST & LAST NAME -->
      <div class="d-sm-flex justify-content-sm-between">
        <FormGroup
          for="firstName"
          label="First Name"
          class="w-50-100"
        >
          <FormInput
            bind-id="firstName"
            required
            get="firstName"
            set="UPDATE_FIRST_NAME"
          />
        </FormGroup>
        <FormGroup
          for="lastName"
          label="Last Name"
          class="w-50-100"
        >
          <FormInput
            bind-id="lastName"
            required
            get="lastName"
            set="UPDATE_LAST_NAME"
          />
        </FormGroup>
      </div>
      <!-- ADDRESS 1 -->
      <FormGroup
        for="shippingAddress1"
        label="Address 1"
        class="w-100"
      >
        <FormInput
          bind-id="shippingAddress1"
          required
          get="shippingAddress1"
          set="UPDATE_SHIPPING_ADDRESS1"
        />
      </FormGroup>
      <!-- ADDRESS 2 -->
      <FormGroup
        for="shippingAddress2"
        label="Address 2 (Optional)"
        class="w-100"
      >
        <FormInput
          bind-id="shippingAddress2"
          get="shippingAddress2"
          set="UPDATE_SHIPPING_ADDRESS2"
        />
      </FormGroup>
      <!-- CITY & STATE/REGION/PROVINCE -->
      <div class="d-sm-flex flex-wrap justify-content-sm-between ">
        <FormGroup
          for="shippingCity"
          label="City"
          class="w-50-100"
        >
          <FormInput
            bind-id="shippingCity"
            required
            get="shippingCity"
            set="UPDATE_SHIPPING_CITY"
          />
        </FormGroup>
        <FormGroup
          v-if="shippingStateOptions.length"
          for="shippingState"
          label="State/Region/Province"
          class="w-50-100"
        >
          <FormSelect
            :options="shippingStateOptions"
            bind-id="shippingState"
            get="shippingState"
            set="UPDATE_SHIPPING_STATE"
            required
          />
        </FormGroup>
        <!-- ZIP -->
        <FormGroup
          for="shippingZip"
          label="Zip/Postal Code"
          class="w-50-100"
        >
          <FormInput
            bind-id="shippingZip"
            required
            get="shippingZip"
            set="UPDATE_SHIPPING_ZIP"
          />
        </FormGroup>
        <FormGroup
          for="sameBillingShipping"
          class="w-100"
        >
          <FormInput
            bind-id="sameBillingShipping"
            type="checkbox"
            label="Use this as my billing address"
            get="sameBillingShipping"
            set="TOGGLE_SAME_BILLING_SHIPPING"
            class="w-100"
          />
        </FormGroup>
      </div>
    </FormSection>
    <FormSection
      v-if="showNfbConsent"
      title="NFB Consent"
    >
      <FormInput
        bind-id="showNfbConsent"
        type="checkbox"
        label="I am a member of the National Federation of the Blind, and I consent to have my personal information shared with National Federation of the Blind for verification purposes."
        get="nfbConsent"
        set="SET_NFB_CONSENT"
        class="w-100"
      />
    </FormSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hardware from '../../data/hardware.json';

import SignupPlan from './SignupComponents/SignupPlan.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';
import FormRadio from '../Form/FormRadio.vue';

export default {
  name: 'SignupStep1',
  components: {
    SignupPlan,
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    FormRadio,
  },
  computed: {
    ...mapGetters([
      'countryOptions',
      'shippingStateOptions',
      'planCode',
      'plan',
      'hardwareOptions',
      'hardwareSelected',
      'hardwareSelectedInfo',
      'shippingCountry',
      'couponCodes',
    ]),
    showNfbConsent() {
      // eslint-disable-next-line
      return this.planCode === 'm140-tmonthly-c99-s012718-nnfb' || this.couponCodes.find((coupon) => {
        return coupon.toLowerCase() === 'nfb-intro-discount';
      });
    },
    hardwarePreferences() {
      if (!this.hardwareSelected || this.hardwareSelected === 'none') return [];
      const pref = hardware[this.hardwareSelected].preferences;
      if (!pref) return [];
      return hardware.preferences[pref];
    },
    showShipping() {
      return this.hardwareSelected !== 'none';
    },
  },
};
</script>

<style lang="scss" scoped>
#signup-step-1 {
  padding: 20px;
  padding-top: 35px;
}
</style>
