<template>
  <div id="signup-step-0">
    <div v-if="!noPlans">
      <!-- COUNTRY CODE & PHONE NUMBER -->
      <FormSection
        v-if="!verifying"
        description="Enter your mobile number to begin the sign up process. We'll text you a code to verify your number."
      >
        <div class="d-flex justify-content-between">
          <FormGroup
            :state="state"
            :invalid-feedback="invalidFeedback"
            for="countryCode"
            class="w-25-35"
          >
            <FormSelect
              :options="countryCodeOptions"
              :state="state"
              aria-label="country code"
              bind-id="countryCode"
              default="+1"
              get="countryCode"
              set="UPDATE_COUNTRY_CODE"
            />
          </FormGroup>
          <FormGroup
            for="phoneNumber"
            class="w-75-65"
          >
            <FormInput
              :state="state"
              aria-label="phone number"
              type="tel"
              bind-id="phoneNumber"
              get="phoneNumber"
              set="UPDATE_PHONE_NUMBER"
              placeholder="Mobile Number"
              @keyup.enter.native="sendCode"
            />
          </FormGroup>
        </div>
      </FormSection>
      <FormSection
        v-if="verifying"
        :description="`Enter the 4-digit code texted to you at [${phone}]`"
      >
        <FormGroup
          id="verification-code"
          :state="state"
          :class="[{'invalid': state}]"
          :invalid-feedback="invalidFeedback"
          for="auth"
          role="alert"
        >
          <b-input
            id="verification-code-field"
            v-model="auth"
            type="tel"
            maxlength="4"
          />
        </FormGroup>
      </FormSection>
      <b-button
        v-if="!verifying"
        :disabled="disabled"
        size="lg"
        variant="primary"
        @click="sendCode"
      >
        Get Started
      </b-button>
      <b-button
        v-if="verifying"
        :disabled="disabled"
        class="link-button"
        size="sm"
        variant="link"
        @click="sendCode"
      >
        {{ resendCodeText }}
      </b-button>
      <b-button
        v-if="verifying"
        class="link-button"
        size="sm"
        variant="link"
        @click="back"
      >
        Back
      </b-button>
    </div>
    <div v-else>
      You already have a subscription.
      <br>
      Open the Aira app today and start exploring!
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'SignupStep0',
  components: {
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
  },
  data() {
    return {
      disabled: false,
      auth: '',
      verifying: false,
      timer: 0,
      state: null,
      invalidFeedback: '',
      noPlans: false,
    };
  },
  computed: {
    ...mapGetters([
      'countryCodeOptions',
      'countryCode',
      'phone',
      'phoneNumber',
    ]),
    resendCodeText() {
      if (this.timer > 0) {
        return `Resend Code in ${this.timer}`;
      } else {
        return 'Resend Code';
      }
    },
  },
  watch: {
    auth(val) {
      if (val.length === 4) {
        this.verify();
      }
    },
  },
  methods: {
    back() {
      this.verifying = false;
      this.disabled = false;
    },
    async sendCode() {
      this.disabled = true;
      if (this.phoneNumber.length < 7) {
        this.state = 'invalid';
        this.invalidFeedback = 'Error: invalid number';
        this.disabled = false;
      } else {
        try {
          await this.$store.dispatch('signupVerify');
          this.verifying = true;
          this.state = null;
          this.invalidFeedback = '';
          this.minuteCountdown();
        } catch (error) {
          console.error(error);
          this.state = 'invalid';
          this.invalidFeedback = error.errorMessage;
          this.disabled = false;
        }
      }
    },
    async verify() {
      try {
        const response = await this.$store.dispatch('signupVerifyConfirm', this.auth);
        if (response) {
          // existing user, take to error screen, no upgrades available
          if (response.hasSubscription) {
            // send to error
            this.noPlans = true;
          } else if (response.newUser) { // new user
            this.$store.commit('SET_STEP', 1);
            this.$store.commit('TOGGLE_PHONE_VERIFIED', true);
            this.$store.commit('UPDATE_VERIFICATION', response.verificationCode);
          } else { // guest
            // login and set guest
            const login = await this.$store.dispatch('login', {
              login: this.phone,
              password: response.verificationCode,
              authProvider: 'PHONE_VERIFICATION',
              loginfrom: 'SSP',
            });
            if (login) {
              this.$store.commit('TOGGLE_GUEST', true);
              this.$store.commit('SET_STEP', 1);
              this.$store.commit('TOGGLE_PHONE_VERIFIED', true);
            } else {
              this.state = 'invalid';
              this.invalidFeedback = 'error';
              this.auth = '';
            }
          }
          // update country code options to disable
          const options = this.countryCodeOptions;
          options.forEach((option) => {
            // eslint-disable-next-line
            if (option.value !== this.countryCode) option.disabled = true;
          });
          this.$store.commit('SET_COUNTRY_CODE_OPTIONS', options);
        } else {
          this.state = 'invalid';
          this.invalidFeedback = 'error';
          this.auth = '';
        }
      } catch (error) {
        console.error(error);
        this.state = 'invalid';
        this.invalidFeedback = `Error: ${error.errorMessage}`;
        this.auth = '';
      }
    },
    minuteCountdown() {
      this.disabled = true;
      this.timer = 60;
      const minuteTimer = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0) {
          this.disabled = false;
          clearInterval(minuteTimer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
$char-w: 1ch;
$gap: .4*$char-w;
$n-char: 4;
$in-w: $n-char*($char-w + $gap);

#signup-step-0 {
  padding: 20px;
  padding-top: 35px;
  button {
    display: block;
    margin: 0 auto;
  }
  .link-button {
    height: 25px;
  }
  .invalid-feedback {
    position: absolute;
  }
  #verification-code {
    width: 170px;
    height: 55px;
    margin: 2em auto;
    border: 1px solid;
    padding: 0px 25px 5px 30px;
    border-radius: 8px;
    &:focus-within {
      border: 1px solid #21BECE;
    }
    .invalid-feedback {
      display: inline-flex !important;
      position: relative;
      margin-left: -50px;
      margin-top: 10px;
      width: 250px;
    }
    #verification-code-field {
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      font-weight: 600;
      font-size: 36px !important;
      line-height: 43px;
      display: block;
      width: $in-w;
      background: repeating-linear-gradient(90deg,
        dimgrey 0, dimgrey $char-w,
        transparent 0, transparent $char-w + $gap)
        0 100%/ #{$in-w - $gap} 3px no-repeat;
      letter-spacing: $gap;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
</style>
