<template>
  <div id="signup">
    <div class="signup-container">
      <div
        ref="title"
        class="signup-title"
        tabindex="0"
        v-html="titleHtml"
      />
      <b-form
        class="form-container"
        @submit.prevent
      >
        <FormProgressBar :progress="progress" />
        <div class="form">
          <!-- NOTICE(S) -->
          <div
            v-if="showNotice"
          >
            <FormNotice
              v-for="(message, index) in notice"
              :key="index"
              :message="message"
            />
          </div>
          <!-- WARNING(S) -->
          <div
            v-if="showWarning"
          >
            <FormNotice
              v-for="(message, index) in warning"
              :key="index"
              :message="message"
              type="warning"
            />
          </div>
          <!-- ERROR HANDLING -->
          <FormErrors />
          <!-- STEPS -->
          <!-- recurly script -->
          <div>
            <script2 src="https://js.recurly.com/v4/recurly.js" />
            <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
            <!-- eslint-disable -->
            <script2>
              $(document).ready(function () {
                recurly.configure({
                  publicKey: '{{publicKey}}',
                });
              });

              recurly.on('field:submit', function (event) {
                event.preventDefault();
              });

              $('form').on('submit', function (event) {
                event.preventDefault();
                var form = this;

                recurly.token(form, function (err, token) {
                  if (err) {
                    console.log(err);
                    alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
                  } else {
                    $('#token').val(token.id);
                    $('#signupButton').click();
                  }
                });
              });
            </script2>
            <!-- eslint-enable -->
          </div>
          <SignupStep0 v-if="showStep(0)" />
          <SignupStep1 v-if="showStep(1)" />
          <SignupStep2 v-show="showStep(2)" />
          <SignupStep3 v-if="showStep(3)" />
          <input
            id="token"
            ref="token"
            type="hidden"
            name="recurly-token"
            data-recurly="token"
          >
          <vue-recaptcha
            v-if="phoneVerified && step === 3"
            id="recaptcha"
            ref="recaptcha"
            :load-recaptcha-script="true"
            :sitekey="recaptchaSiteKey"
            @verify="recaptchaVerify"
          />
          <div class="signup-buttons d-flex justify-content-center">
            <b-button
              v-if="showBackButton"
              :disabled="buttonDisabled"
              variant="outline-primary"
              size="lg"
              @click="$store.commit('DECREMENT_STEP')"
            >
              Back
            </b-button>
            <b-button
              v-if="phoneVerified && !showStep(3)"
              :disabled="buttonDisabled"
              size="lg"
              variant="primary"
              @click="clickSignupForm"
            >
              {{ buttonText }}
            </b-button>
            <b-button
              v-if="phoneVerified && showStep(3)"
              :disabled="buttonDisabled"
              type="submit"
              size="lg"
              variant="primary"
            >
              {{ buttonText }}
            </b-button>
            <b-btn
              id="signupButton"
              hidden
              @click="handleSubmitRedirect()"
            />
          </div>
          <SignupAgreementClause
            v-if="showAgreementClause"
            variant="signup"
          />
        </div>
      </b-form>
    </div>
    <DevTools v-if="dev" />
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';
import { validateEmail } from '../utils/validation';
import API from '../api';
import form from '../data/form.json';

import SignupStep0 from './Signup/SignupStep0.vue';
import SignupStep1 from './Signup/SignupStep1.vue';
import SignupStep2 from './Signup/SignupStep2.vue';
import SignupStep3 from './Signup/SignupStep3.vue';

import FormNotice from './Form/FormNotice.vue';
import FormErrors from './Form/FormErrors.vue';
import SignupAgreementClause from './Signup/SignupComponents/SignupAgreementClause.vue';

import FormProgressBar from './Form/FormProgressBar.vue';
import DevTools from './Tools/DevTools.vue';

export default {
  name: 'Signup',
  components: {
    VueRecaptcha,
    SignupStep0,
    SignupStep1,
    SignupStep2,
    SignupStep3,
    DevTools,
    FormNotice,
    FormErrors,
    SignupAgreementClause,
    FormProgressBar,
  },
  data() {
    return {
      // move dev prod variables to store const
      dev: process.env.NODE_ENV === 'development',
      prod: process.env.NODE_ENV === 'production',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA,
      recaptchaVerified: false,
      buttonDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'operatingCountry',
      'isInternal',
      'step',
      'referral',
      'couponCodes',
      'planCode',
      'plan',
      'errors',
      'warning',
      'notice',
      'horizonSize',
      'horizonTint',
      'hardwareSelected',
      'firstName',
      'lastName',
      'shippingAddress1',
      'shippingCity',
      'shippingState',
      'shippingCountry',
      'shippingZip',
      'address1',
      'city',
      'state',
      'country',
      'zip',
      'email',
      'countryCode',
      'phoneNumber',
      'phoneVerified',
      'password',
      'nfbConsent',
      'leadCode',
      'leadValue',
    ]),
    titleHtml() {
      return `<h1
        class="signup-title">
        ${this.title}
      </h1>`;
    },
    title() {
      if (this.isGuest) return 'Internal Guest Upgrade Form';
      switch (this.step) {
        case 0: return 'Verify Your Number';
        case 1: return 'Your Cart';
        case 2: return 'Let\'s Get Started';
        case 3: return 'Billing';
        default: return 'Loading . . .';
      }
    },
    buttonText() {
      if (this.buttonDisabled) return 'Processing...';
      if (this.isGuest) return 'Upgrade Guest Account';
      switch (this.step) {
        case 0:
        case 1:
        case 2: return 'Continue';
        case 3: return 'Place Order';
        default: return '...don\'t click this!!';
      }
    },
    progress() {
      return {
        step1: (this.step > 0),
        step2: (this.step > 1),
        step3: (this.step > 2),
        full: (this.isInternal || this.step === 0),
      };
    },
    showBackButton() {
      return !this.isInternal && (this.step > 1);
    },
    showNotice() {
      return this.step === 1 && (this.referral || this.couponCodes.length);
    },
    showWarning() {
      return this.warning && this.step === 1;
    },
    showAgreementClause() {
      return (this.step === 1 && this.hardwareSelected !== 'none')
                || this.step === 2
                || this.step === 3
                || this.step === 0;
    },
  },
  watch: {
    step(val) {
      this.$store.commit('CLEAR_ERRORS');
      this.buttonDisabled = false;

      // return focus to top of page on page change
      window.scrollTo(0, 0);
      this.$nextTick(() => {
        this.$refs.title.focus();
      });

      // prevent out of bound step, reset if out of bound
      if (val < 0 || val > 3) {
        // eslint-disable-next-line
          alert('HOW DARE YOU. Returning to the start.');
        this.$store.commit('SET_STEP', 1);
      }

      // google analytics screen tracking on step change
      if (this.prod) this.trackPage();
    },
  },
  // validations: {

  // },
  mounted() {
    // google analytics
    if (this.prod) {
      // this.track();
      this.trackPage();
    }

    // referral
    if (this.$route.query.referral) {
      // validate
      this.$store.dispatch('getReferralInfo', this.$route.query.referral).then((response) => {
        if (response) {
          const formOption = form.leadOptions.find((option) => option.value === 'Referral');
          formOption.disabled = true;
          this.$store.commit('PUSH_NOTICE', 'By accepting this referral your first month with Aira is free! Please note this promotion does not cover the cost of the Horizon Kit.');
        } else {
          this.$store.commit('PUSH_WARNING', 'The referral code you are using is invalid. Don\'t worry. You may enter your referrer\'s email in the next step.');
        }
      });
    }

    // coupons
    if (this.$route.query.coupon) {
      const couponCodes = this.$route.query.coupon.split(',').filter((item, pos, self) => self.indexOf(item) === pos);

      couponCodes.forEach((coupon) => {
        this.$store.dispatch('validateCoupon', coupon).then((response) => {
          if (response) {
            // apply only att or nfb intro discount, not both, unfortunately is asynchronous and works on whatever is applied first
            // eslint-disable-next-line
              if (!((coupon.toLowerCase() === 'nfb-intro-discount' || coupon.toLowerCase() === 'att-perks-at-work') && (this.couponCodes.includes('nfb-intro-discount') || this.couponCodes.includes('att-perks-at-work')))) {
              this.$store.commit('APPLY_COUPON', coupon);
            } else {
              this.$store.commit('PUSH_WARNING', `Coupon code <b>${coupon}</b> is not available because <b>nfb-intro-discount</b> and <b>att-perks-at-work</b> cannot be applied at the same time.`);
              return;
            }
            // TODO: WRITE BETTER CODE TO SANITIZE & PARSE COUPON INPUT FROM POTENTIAL INJECTION
            if (coupon === 'att-perks-at-work') {
              // eslint-disable-next-line
                this.$store.commit('PUSH_NOTICE', `<b>Perks@Work AT&T Employee plan</b>: For $20 per month, the plan includes 30 minutes to connect with Aira's professional network of agents. This is a service only plan not eligible for Horizon Smart Glasses upgrade.`);
            } else if (coupon === 'nfb-intro-discount') {
              // eslint-disable-next-line
                this.$store.commit('PUSH_NOTICE', `<b>NFB Intro Plan Discount</b>: You will receive 30% off the Intro plan for the next 12 months or until January 25th, 2021, whichever comes first.`);
            } else {
              // eslint-disable-next-line
                this.$store.commit('PUSH_NOTICE', `Coupon code <b>${coupon.toUpperCase().replace(/-/g, ' ').replace(/_/g, ' ').replace(/</g, '').replace(/>/g, '')}</b> has been applied!`);
            }
          } else {
            // eslint-disable-next-line
              this.$store.commit('PUSH_WARNING', `Coupon code <b>${coupon.toUpperCase().replace(/-/g, ' ').replace(/_/g, ' ').replace(/</g, '').replace(/>/g, '')}</b> is not available. Try entering a new coupon before checking out on the Billing page.`);
          }
        }).catch((error) => {
          console.error(error);
          // eslint-disable-next-line
            this.$store.commit('PUSH_WARNING', `Coupon code <b>${coupon.toUpperCase().replace(/-/g, ' ').replace(/_/g, ' ').replace(/</g, '').replace(/>/g, '')}</b> is invalid. Try entering a new coupon before checking out on the Billing page.`);
        });
      });
    }
  },
  methods: {
    // GOOGLE ANALYTICS
    trackPage() {
      this.$ga.page({
        page: this.$route.fullPath,
        title: `Aira Sign Up - Step ${this.step}`,
      });
    },
    // UTILITIES
    showStep(step) {
      return this.isInternal || this.step === step;
    },
    recaptchaVerify(response) {
      this.$store.dispatch('setRecaptchaToken', response);
      this.recaptchaVerified = true;
    },
    // FORM HANDLERS
    clickSignupForm() {
      this.buttonDisabled = true;
      switch (this.step) {
        case 0:
          this.handleStep0Continue();
          break;
        case 1:
          this.handleStep1Continue();
          break;
        case 2:
          this.handleStep2Continue();
          break;
        case 3:
          this.handleSubmitRedirect();
          break;
        default:
          // eslint-disable-next-line
            alert('You shouldn\'t be here');
          break;
      }
    },
    // STEP 0
    handleStep0Continue() {
      this.$store.commit('SET_STEP', 1);
    },
    // STEP 1
    handleStep1Continue() {
      this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      // eslint-disable-next-line
        if (this.planCode === 'm140-tmonthly-c99-s012718-nnfb' || this.couponCodes.find((coupon) => {
        return coupon.toLowerCase() === 'nfb-intro-discount';
      })) {
        if (!this.nfbConsent) fieldErrors.push('NFB Consent');

        this.$store.commit('PUSH_ERRORS', fieldErrors);
        this.buttonDisabled = false;
        if (!this.errors.length) {
          this.$store.commit('SET_STEP', 2);
        }
      } else if (this.hardwareSelected === 'none') {
        this.$store.commit('SET_STEP', 2);
      } else if ((this.operatingCountry === 'US' || this.operatingCountry === 'CA')
                && (this.shippingCountry !== 'US' && this.shippingCountry !== 'CA')) {
        let errorMessage = 'Shipping to outside of North America will require signing up in their respective country.';
        switch (this.shippingCountry) {
          case 'AU':
            errorMessage += '<a href="https://aira.io/australia">aira.io/australia</a>';
            break;
          case 'NZ':
            errorMessage += '<a href="https://aira.io/new-zealand">aira.io/new-zealand</a>';
            break;
          case 'UK':
            errorMessage += '<a href="https://aira.io/united-kingdom">aira.io/united-kingdom</a>';
            break;
          default:
            errorMessage += '<a href="https://aira.io/pricing-global">aira.io/pricing-global</a>';
        }
        this.$store.commit('PUSH_ERRORS', [errorMessage]);
        this.buttonDisabled = false;
      } else {
        if (!this.horizonSize) fieldErrors.push('Horizon Size');
        if (!this.horizonTint) fieldErrors.push('Horizon Tint');
        if (!this.firstName) fieldErrors.push('First Name');
        if (!this.shippingAddress1) fieldErrors.push('Shipping Address 1');
        if (!this.shippingCountry) fieldErrors.push('Shipping Country');
        if (!this.shippingCity) fieldErrors.push('Shipping City');
        if ((this.shippingCountry !== 'GB') && !this.shippingState) fieldErrors.push('Shipping State/Region/Province');
        if (!this.shippingZip) fieldErrors.push('Shipping Zip/Postal Code');

        this.$store.commit('PUSH_ERRORS', fieldErrors);
        this.buttonDisabled = false;
        if (!this.errors.length) {
          this.$store.commit('SET_STEP', 2);
        }
      }
    },
    // STEP 2
    async handleStep2Continue() {
      this.$store.commit('CLEAR_ERRORS');

      let errors = [];
      const fieldErrors = this.validateFields();
      errors = [...errors, ...fieldErrors];

      let referralErrors;
      try {
        referralErrors = await this.validateReferral();
      } catch (e) {
        console.error(e);
      }

      errors = [...errors, ...referralErrors];
      if (errors.length) {
        this.$store.commit('PUSH_ERRORS', errors);
        this.buttonDisabled = false;
        return;
      }
      try {
        const response = await this.$store.dispatch('getOrderPreview');
        if (response) {
          this.$store.commit('SET_STEP', 3);
        } else {
          this.buttonDisabled = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    validateFields() {
      const fieldErrors = [];

      if (!this.firstName) fieldErrors.push('First Name');
      if (!this.email) fieldErrors.push('Email');
      if (this.email && !validateEmail(this.email)) fieldErrors.push('Email not valid');
      if (this.password.length < 8) fieldErrors.push('Password');
      if ((this.country !== 'GB' && this.country !== 'UK') && !this.state) fieldErrors.push('State/Region/Province');
      if (!this.country) fieldErrors.push('Country');
      if (!this.leadCode) fieldErrors.push('How did you hear about Aira');
      if (this.leadCode && !this.leadValue) fieldErrors.push('Please specify');

      return fieldErrors;
    },
    async validateReferral() {
      if (this.leadCode === 'Referral' && this.leadValue) {
        this.$store.dispatch('verifyReferralEmail', this.leadValue).then((response) => {
          if (!response) {
            return [`Referrer email ${this.leadValue} does not belong to an Explorer.`];
          }
          return [];
        });
      }
      return [];
    },
    async validateEmail() {
      if (this.email) {
        let response;
        try {
          response = await API.isEmailInUse(this.email);
        } catch (e) {
          console.error(e);
          return [];
        }
        // console.log('API~isEmailInUse: ', response);
        if (response.result) {
          return [`${this.email} is already in use. Please contact support@aira.io.`];
        }
        return [];
      }
      return [];
    },
    // STEP 3
    async handleSubmitRedirect() {
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
      }

      // form validation
      this.$store.commit('CLEAR_ERRORS');
      let fieldErrors = [];

      // validation
      const validation = await this.$validator.validateAll();
      if (!validation) {
        const recurlyErrors = await this.veeErrors.items.map((item) => item.msg);
        fieldErrors = fieldErrors.concat(recurlyErrors);
      }

      if (!this.recaptchaVerified) fieldErrors.push('Recaptcha Verification');
      this.$store.commit('PUSH_ERRORS', fieldErrors);

      if (!this.errors.length) {
        this.$store.dispatch('doSignup').then((response) => {
          console.log('Signup.vue: ', response);
          if (response) {
            window.location.href = `https://aira.io/get-started-confirmation?userId=${response}`;
          } else {
            this.buttonDisabled = false;
          }
        });
      } else {
        this.buttonDisabled = false;
      }
    },
  },
};
</script>
<style lang="scss">
  @import '../styles/variables/_colors.scss';
  @import '../styles/variables/_sizes.scss';
  @import '../styles/theme/_default.scss';

  #signup {
    #recaptcha {
      > div {
        margin: 0px auto 30px auto;
      }
    }
    #signup-title:focus {
      outline: 0 !important;
    }
    .signup-container {
      height: 100%;
      padding: 60px 0 60px 0;
      .signup-form-container {
        margin: 50px 5px;
        @media screen and (min-width: $aira-min-width + 10px) {
          width: $aira-min-width;
          margin: 50px auto;
        }
        .signup-form {
          height: auto;
          padding-bottom: 15px;
          background-color: $aira-bg-color-content-container;
        }
      }
    }
  }
</style>
