<template>
  <div id="signup-step-2">
    <!-- ACCOUNT INFORMATION SECTION -->
    <FormSection
      title="Account Information"
    >
      <!-- FIRST & LAST NAME -->
      <div class="d-sm-flex justify-content-sm-between">
        <FormGroup
          for="firstName"
          label="First Name*"
          class="w-50-100"
        >
          <FormInput
            v-validate="{ required: true }"
            :state="validateState('First Name')"
            name="First Name"
            bind-id="firstName"
            data-recurly="first_name"
            invalid-feedback="First Name Required."
            invalid-feedback-id="first-name-invalid-feedback"
            get="firstName"
            set="UPDATE_FIRST_NAME"
            required
          />
        </FormGroup>
        <FormGroup
          for="lastName"
          label="Last Name*"
          class="w-50-100"
        >
          <FormInput
            v-validate="{ required: true }"
            :state="validateState('Last Name')"
            name="Last Name"
            bind-id="lastName"
            data-recurly="last_name"
            invalid-feedback="Last Name Required."
            invalid-feedback-id="last-name-invalid-feedback"
            get="lastName"
            set="UPDATE_LAST_NAME"
            required
          />
        </FormGroup>
      </div>
      <!-- COUNTRY CODE & PHONE NUMBER -->
      <div class="d-flex justify-content-between">
        <FormGroup
          for="countryCode"
          label="Country"
          class="w-25-35"
        >
          <FormSelect
            :options="countryCodeOptions"
            bind-id="countryCode"
            default="+1"
            get="countryCode"
            set="UPDATE_COUNTRY_CODE"
            readonly
          />
        </FormGroup>
        <FormGroup
          for="phoneNumber"
          label="Phone Number"
          class="w-75-65"
        >
          <FormInput
            bind-id="phoneNumber"
            get="phoneNumber"
            set="UPDATE_PHONE_NUMBER"
            readonly
          />
        </FormGroup>
      </div>
      <!-- EMAIL ADDRESS -->
      <FormGroup
        for="email"
        label="Email Address"
        class="w-100"
      >
        <FormInput
          :readonly="!noEmail"
          bind-id="email"
          type="email"
          get="email"
          set="UPDATE_EMAIL"
        />
      </FormGroup>
      <!-- PASSWORD -->
      <FormGroup
        for="password"
        label="Password"
        tip="At least 8 characters"
        class="w-100 tip"
      >
        <FormInput
          bind-id="password"
          type="password"
          get="password"
          set="UPDATE_PASSWORD"
        />
      </FormGroup>
      <!-- COUNTRY AND STATE -->
      <div class="d-sm-flex justify-content-sm-between">
        <FormGroup
          for="country"
          label="Country"
          class="w-50-100"
        >
          <FormSelect
            :options="countryOptions"
            bind-id="country"
            get="country"
            set="UPDATE_COUNTRY"
          />
        </FormGroup>
        <FormGroup
          v-if="stateOptions.length"
          for="state"
          label="State/Region/Province"
          class="w-50-100"
        >
          <FormSelect
            :options="stateOptions"
            bind-id="state"
            get="state"
            set="UPDATE_STATE"
          />
        </FormGroup>
      </div>
      <!-- HOW DID YOU HEAR ABOUT AIRA? -->
      <SignupLeadSurvey />
    </FormSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ValidationMixin from '../../mixin/ValidationMixin';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';
import SignupLeadSurvey from './SignupComponents/SignupLeadSurvey.vue';

export default {
  name: 'SignupStep2',
  components: {
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    SignupLeadSurvey,
  },
  mixins: [
    ValidationMixin,
  ],
  computed: {
    ...mapGetters([
      'countryCodeOptions',
      'countryOptions',
      'stateOptions',
      'noEmail',
      'noPhone',
    ]),
  },
};
</script>

<style lang="scss" scoped>
#signup-step-2 {
  padding: 20px;
  padding-top: 35px;
}
</style>
