<template>
  <div
    ref="notice"
    :class="[{ warning: isWarning }, 'signup-notice' ]"
  >
    <p><small v-html="message" /></p>
  </div>
</template>

<script>
// TODO use bootstrap vue alert component
export default {
  name: 'FormNotice',
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'notice',
    },
  },
  data() {
    return {
      isWarning: this.type === 'warning',
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';

.signup-notice {
  position: relative;
  top: 25px;
  line-height: 1;
  margin: 0 20px 10px 20px;
  padding: 10px 15px;
  background-color: $aira-color-notice-bg;
  border: 2px solid $aira-color-notice-border;
  border-radius: 2px;
  text-align: left;
}
.warning {
  background-color: $aira-color-warning-bg !important;
  border: 2px solid $aira-color-warning-border !important;
}
</style>
