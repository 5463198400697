<template>
  <div class="signup-summary-info">
    <!-- PAYMENT PROFILE -->
    <div v-if="variant === 'payment-profile'">
      <div v-if="cardType">
        <p>{{ ccFirstName }} {{ ccLastName }}</p>
        <p>{{ cardType }} ending in {{ ccLastFour }}</p>
        <p>{{ address1 }}</p>
        <p>{{ city }} , {{ state }} {{ zip }} {{ country }}</p>
      </div>
      <div v-else-if="achAccountType">
        <p>{{ achFullName }}</p>
        <p class="text-uppercase">
          {{ achAccountType }}
        </p>
        <p>{{ achRoutingNumber }}</p>
        <p>XXXX-{{ achAccountNumberLastFour }}</p>
      </div>
      <div v-else>
        Your payment profile is incomplete. Please update your payment profile before proceeding.
      </div>
    </div>
    <!-- PAYMENT -->
    <div v-if="variant === 'payment'">
      <p v-if="ccLastFour">
        {{ cardType }} ending in {{ ccLastFour }}
      </p>
      <p v-else>
        Card ending in {{ cc.substr(cc.length-4) }}
      </p>
    </div>
    <!-- BILLING -->
    <div v-if="variant === 'billing' && (sameBillingShipping && hardwareSelected !== 'none')">
      <p>Same as shipping address</p>
    </div>
    <div v-else-if="variant === 'billing'">
      <p>{{ firstName }} {{ lastName }}</p>
      <p>{{ address1 }}</p>
      <p>{{ city }} , {{ state }} {{ zip }}</p>
      <p>{{ country }}</p>
    </div>
    <!-- SHIPPING -->
    <div v-if="variant === 'shipping'">
      <p>{{ firstName }} {{ lastName }}</p>
      <p>{{ shippingAddress1 }}</p>
      <p v-if="shippingAddress2">
        {{ shippingAddress2 }}
      </p>
      <p>{{ shippingCity }}, {{ shippingState }} {{ shippingZip }}</p>
      <p>{{ shippingCountry }}</p>
    </div>
    <!-- ACCOUNT -->
    <div v-if="variant === 'account'">
      <p>{{ firstName }} {{ lastName }}</p>
      <p>{{ email }}</p>
      <p>{{ phone }}</p>
    </div>

    <b-button
      class="signup-summary-info-button"
      variant="link"
      @click.prevent="clickHandler"
    >
      {{ linkText }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SignupSummaryInfo',
  props: {
    variant: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: false,
      default: 0,
    },
    route: {
      type: String,
      required: false,
      default: '',
    },
    linkText: {
      type: String,
      required: false,
      default: 'Edit',
    },
    message: {
      type: String,
      required: false,
      default: 'Oops, something went wrong...',
    },
  },
  computed: {
    ...mapGetters([
      'firstName',
      'lastName',
      'address1',
      'country',
      'city',
      'state',
      'zip',
      'shippingAddress1',
      'shippingAddress2',
      'shippingCountry',
      'shippingCity',
      'shippingState',
      'shippingZip',
      'sameBillingShipping',
      'email',
      'phone',
      'cc',
      'cardType',
      'ccLastFour',
      'ccFirstName',
      'ccLastName',
      'achFullName',
      'achAccountType',
      'achRoutingNumber',
      'achAccountNumberCensored',
      'achAccountNumberLastFour',
      'hardwareSelected',
    ]),

  },
  methods: {
    clickHandler() {
      if (this.step > 0) {
        this.$store.commit('SET_STEP', this.step);
      } else if (this.route) {
        this.$router.push(this.route);
      } else {
        // eslint-disable-next-line
        confirm(this.message);
      }
    },
  },
};
</script>

<style lang="scss">
.signup-summary-info {
  > div {
    display: inline;
  }
  p {
    margin-bottom: 0 !important;
    line-height: 1.25;
  }
  .signup-summary-info-button {
    padding: 0;
    display: inline;
    height: auto;
    font-weight: lighter;
  }
}
</style>
