<template>
  <div id="signup-plan">
    <div class="signup-plan-container">
      <div class="signup-plan-info">
        <h6>{{ plan.planName }}</h6>
        <p><small>{{ cost }} per month | {{ plan.minutes }} min per month</small></p>
      </div>
      <div class="signup-plan-action float-right">
        <a
          :href="returnUrl"
          class="signup-plan-change-link aira-action-link float-right"
        >
          <small>Change Plan</small>
        </a>
      </div>
    </div>
    <span
      class="signup-plan-blurp"
    >
      {{ blurp }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import plans from '../../../data/plans.json';

export default {
  name: 'SignupPlan',
  computed: {
    ...mapGetters([
      'operatingCountry',
      'planCode',
      'plan',
      'referral',
    ]),
    returnUrl() {
      let url = '';
      switch (this.operatingCountry) {
        case 'AU':
          url += 'https://aira.io/australia';
          break;
        case 'NZ':
          url += 'https://aira.io/new-zealand';
          break;
        case 'GB':
          url += 'https://aira.io/uk';
          break;
        case 'US':
        case 'cs':
          url += 'https://aira.io/pricing';
          break;
        default:
          url += 'https://aira.io/pricing-global';
      }
      if (this.referral) url += `?referral=${this.referral}`;
      return url;
    },
    cost() {
      if (!this.plan.currency) return '';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.plan.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      }).format(this.plan.price / 100);
    },
    blurp() {
      if (!plans[this.planCode]) return '';
      return plans[this.planCode].blurp;
    },
  },
};
</script>

<style lang='scss' scoped>
  @import '../../../styles/objects/_form.scss';

  #signup-plan {
    .signup-plan-container {
      @extend .aira-input;
      height: auto;
      padding: 15px 15px 10px 15px;
      margin-top: 10px;
      .signup-plan-info {
        line-height: 1;
      }
      div, a {
        display: inline-block;
      }
      .signup-plan-action {
        height: 100%;
        .signup-plan-change-link {
          line-height: 100%;
          display: inline-block;
        }
      }
    }
    .signup-plan-blurp {
      opacity: 0.7;
      font-size: 13px;
      font-style: italic;
      margin: 15px 0;
      line-height: 1;
    }
    h6 {
      margin-bottom: 0;
    }
  }
</style>
