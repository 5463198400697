<template>
  <div id="signup-step-3">
    <!-- recurly script -->
    <div>
      <script2 src="https://js.recurly.com/v4/recurly.js" />
      <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
      <!-- eslint-disable -->
      <script2>
        recurly.configure({
          publicKey: '{{publicKey}}',
        });
      </script2>
      <!-- eslint-enable -->
    </div>
    <div class="signup-billing-form-container">
      <section class="signup-account-info-section d-sm-flex justify-content-sm-between">
        <!-- ACCOUNT INFORMATION SECTION -->
        <FormSection
          :half="true"
          title="Account Information"
          class="w-50-100"
        >
          <SignupSummaryInfo
            :step="2"
            variant="account"
            link-text="Edit"
          />
        </FormSection>
        <!-- SHIPPING ADDRESS SECTION -->
        <FormSection
          v-if="showShippingInfo"
          :half="true"
          title="Shipping Address"
          class="w-50-100"
        >
          <SignupSummaryInfo
            :step="1"
            variant="shipping"
            link-text="Edit"
          />
        </FormSection>
      </section>
      <!-- BILLING INFO SECTION -->
      <FormSection
        title="Billing Information"
      >
        <!-- BILLING ADDRESS 1 -->
        <FormGroup
          for="address1"
          label="Address*"
          class="w-100"
        >
          <FormInput
            v-validate="{ required: true }"
            :state="validateState('Address')"
            name="Address"
            bind-id="address1"
            data-recurly="address1"
            invalid-feedback="Address Required."
            invalid-feedback-id="address1-invalid-feedback"
            get="address1"
            set="UPDATE_ADDRESS1"
            required
          />
        </FormGroup>
        <!-- BILLING CITY & STATE/REGION/PROVINCE-->
        <div class="d-sm-flex justify-content-sm-between">
          <FormGroup
            for="city"
            label="City*"
            class="w-50-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('City')"
              name="City"
              bind-id="city"
              data-recurly="city"
              invalid-feedback="City Required."
              invalid-feedback-id="city-invalid-feedback"
              get="city"
              set="UPDATE_CITY"
              required
            />
          </FormGroup>
          <FormGroup
            for="state"
            label="State/Province/Region*"
            class="w-50-100"
          >
            <FormSelect
              :options="stateOptions"
              :state="null"
              name="state"
              bind-id="state"
              data-recurly="state"
              invalid-feedback="State Required."
              invalid-feedback-id="state-invalid-feedback"
              get="state"
              set="UPDATE_STATE"
              required
            />
          </FormGroup>
        </div>
        <!-- COUNTRY AND ZIP/POSTAL -->
        <div class="d-sm-flex justify-content-sm-between">
          <FormGroup
            for="country"
            label="Country*"
            class="w-50-100"
          >
            <FormSelect
              :options="countryOptions"
              :state="null"
              name="country"
              bind-id="country"
              data-recurly="country"
              invalid-feedback="Country Required."
              invalid-feedback-id="country-invalid-feedback"
              get="country"
              set="UPDATE_COUNTRY"
              required
            />
          </FormGroup>
          <FormGroup
            for="zip"
            label="Zip or Postal Code*"
            class="w-50-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Zip')"
              name="Zip"
              bind-id="zip"
              data-recurly="postal_code"
              invalid-feedback="Zip or Postal Code Required."
              invalid-feedback-id="zip-invalid-feedback"
              get="zip"
              set="UPDATE_ZIP"
              required
            />
          </FormGroup>
        </div>
      </FormSection>
      <!-- PAYMENT INFORMATION SECTION -->
      <FormSection
        title="Payment Information"
      >
        <!-- CREDIT CARD -->
        <FormGroup
          for="cc"
          label="Card Number*"
          class="w-100"
        >
          <div
            class="recurly-field"
            role="none"
            data-recurly="number"
          />
        </FormGroup>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- EXPIRATION & CVC -->
          <FormGroup
            for="expiryMonth"
            label="Expiration Month (MM)*"
            class="w-30-100"
          >
            <div
              class="recurly-field"
              role="none"
              data-recurly="month"
            />
          </FormGroup>
          <FormGroup
            for="expiryYear"
            label="Expiration Year (YYYY)*"
            class="w-30-100"
          >
            <div
              class="recurly-field"
              role="none"
              data-recurly="year"
            />
          </FormGroup>
          <FormGroup
            for="cvv"
            label="CVV*"
            class="w-30-100"
          >
            <FormInput
              :state="null"
              name="CVV"
              bind-id="cvv"
              data-recurly="cvv"
              invalid-feedback="CVV Required."
              invalid-feedback-id="cvv-invalid-feedback"
              get="cvv"
              set="UPDATE_CVV"
              required
            />
          </FormGroup>
        </div>
        <!-- PROMO CODE -->
        <FormCoupon />
      </FormSection>
    </div>
    <!-- ORDER SUMMARY -->
    <SignupOrderSummary />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ValidationMixin from '../../mixin/ValidationMixin';

import SignupSummaryInfo from './SignupComponents/SignupSummaryInfo.vue';
import SignupOrderSummary from './SignupComponents/SignupOrderSummary.vue';

import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';
import FormCoupon from '../Form/FormCoupon.vue';

export default {
  name: 'SignupStep3',
  components: {
    SignupSummaryInfo,
    SignupOrderSummary,
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    FormCoupon,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      // move dev prod variables to store const
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
    };
  },
  computed: {
    ...mapGetters([
      'countryOptions',
      'stateOptions',
      'hardwareSelected',
      'country',
    ]),
    showShippingInfo() {
      return this.hardwareSelected !== 'none';
    },
  },
};
</script>

<style lang="scss" scoped>
#signup-step-3 {
  .signup-billing-form-container {
    padding: 20px;
    padding-top: 35px;
  }
}
</style>
